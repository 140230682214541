const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://o6yu8eayuj.execute-api.ap-southeast-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://293rq1q1ed.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://beigia0a0g.execute-api.ap-southeast-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.3',
    HOSTNAME: 'https://locations.staging.nsr.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.nsr.forwoodsafety.com',
    WEBSOCKET: 'wss://dvldmv3ov7.execute-api.ap-southeast-2.amazonaws.com/staging'
  },
};

export default config;